import { addDrillHole } from './addDrillHole';
import { addDuplicateSample } from './addDuplicateSample';
import { addFileGroup } from './addFileGroup';
import { cloneDrillHole } from './cloneDrillHole';
import { copyCells } from './copyCells';
import { copyCoordinateCells } from './copyCoordinateCells';
import { copyIntervals } from './copyIntervals';
import { createRows } from './createRows';
import { customExport } from './customExport';
import { deleteCoordinatesTableRow } from './deleteCoordinatesTableRow';
import { deleteDrillHole } from './deleteDrillHole';
import { deleteFile } from './deleteFile';
import { deleteRowReferences } from './deleteRowReferences';
import { downloadFile } from './downloadFile';
import { dumpTableData } from './dumpTableData';
import { exportCollars } from './exportCollars';
import { fillDownSaveRowReference } from './fillDownSaveRowReferences';
import { getEmptyFileGroups } from './getEmptyFileGroups';
import { getFilesForGroup } from './getFilesForGroup';
import { loadDrillhole } from './loadDrillhole';
import { loadDrillholeValidationErrors } from './loadDrillholeValidationErrors';
import { loadDrillholeCount, loadDrillholes } from './loadDrillholes';
import { loadLabCertificateInfo } from './loadLabCertificateInfo';
import { loadRankedColumns } from './loadRankedColumns';
import { loadRowReferences } from './loadRowReferences';
import { loadSample } from './loadSample';
import { loadSampleResults } from './loadSampleResults';
import { loadSamples } from './loadSamples';
import { reIndex } from './reIndex';
import { saveCoordinatesTable } from './saveCoordinatesTable';
import { saveDrillHole } from './saveDrillHole';
import { saveRowReference } from './saveRowReference';
import { sortRowReferences } from './sortRowReferences';
import { unassignUserFromDrillhole } from './unassignUserFromDrillhole';
import { uploadFile } from './uploadFile';

export const drillholeShim = {
    loadDrillholeCount,
    loadDrillholes,
    loadDrillhole,
    loadDrillholeValidationErrors,
    loadRowReferences,
    saveRowReference,
    saveDrillHole,
    fillDownSaveRowReference,
    addFileGroup,
    uploadFile,
    getFilesForGroup,
    deleteFile,
    downloadFile,
    deleteRowReferences,
    dumpTableData,
    createRows,
    copyIntervals,
    addDrillHole,
    reIndex,
    deleteDrillHole,
    getEmptyFileGroups,
    loadSamples,
    loadSample,
    addDuplicateSample,
    loadSampleResults,
    loadRankedColumns,
    loadLabCertificateInfo,
    cloneDrillHole,
    unassignUserFromDrillhole,
    saveCoordinatesTable,
    deleteCoordinatesTableRow,
    exportCollars,
    sortRowReferences,
    customExport,
    copyCells,
    copyCoordinateCells,
};
